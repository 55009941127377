//Creating variables for the colours on the webpage
$defaultBackground: rgb(109, 109, 109);
$defaultAlt: rgb(158, 158, 158);
$darkBackground: rgb(44, 44, 44);
$darkAlt: rgb(145, 145, 145);
$lightBackground: rgb(196, 196, 196);
$lightAlt: rgb(93, 93, 93);

//Creating variables for the different text sizes on the website
$headingText: 48px;
$subheadingText: 36px;
$smallText: 28px;
$buttonText: 24px;

//Applying the default styling for the body of the webpage
body {
  /* Removing the default padding and margin*/
  margin: 0px;
  padding: 0px;
  /* Making it so that all text on the website it Roboto */
  font-family: "Roboto", "San-Serif";
  color: white;
  font-weight: 600;
  overflow-x: hidden;
}

/* Removing default padding and margin from p tags*/
p {
  margin: 0px;
  padding: 0px;

  &.centered{
    text-align: center;
  }
  &.error{
    font-size: $smallText;
    color: red;
  }

  &.ChangePassword{
    font-size: $smallText;
    color: black;
  }
}

/* Generic image stylign for images on the website */
img {
  width: 48px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 30px;

  &.corners {
    border-radius: 0%;
  }
}

//Styling all buttons on the website
button {
  width: 40%;
  padding: 10px;
  font-size: $buttonText;
  font-weight: 600;
  margin: 10px;
  border: none;
  cursor: pointer;
  background-color: orange;
  border-radius: 20px;

  &.changePassword{
    padding: 5px;
    width: 30%;
  }

  &:hover {
    border: 2px solid white;
    cursor: pointer;
  }

  &.submit {
    background-color: rgb(0, 114, 0);
  }

  &.delete {
    background-color: red;
  }

  &.exit {
    background-color: red;
  }

  &.complete {
    background-color: gold;
  }
}

//Styling all forms on the website
//Applying styling to all forms on the website
form {
  /* Styling the file input part of forms*/
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px;
    cursor: pointer;

    span {
      font-size: 16px;
    }

    img {
      width: 32px;
    }
  }

  //Styling all inputs on the website
  input {
    padding: 10px;
    margin: 10px;
    width: 50%;
    font-size: $smallText;
    cursor: pointer;
    border: none;
    color: black;
    font-weight: 600;
    background-color: transparent;
    border-bottom: 2px solid lightgray;

    &:focus {
      border: none;
    }
  }

  &.white{
    color: white;
  }

  //Styling the select and options within the form
  select {
    padding: 10px;
    width: 15vw;
    font-size: $smallText;
    border: none;
    color: black;
    border-bottom: 2px solid white;
    font-weight: 600;
    background-color: transparent;

    &:focus {
      border: none;
    }

    &.large {
      font-size: $subheadingText;
      color: white;
    }

    &.light {
      color: black;
      border-bottom: 2px solid black;
    }

    option {
      color: black;
    }
  }

  ::placeholder {
    font-size: $buttonText;
    color: $darkBackground;
  }

  .light {
    &::placeholder {
      color: white;
    }
  }

  //Displaying the error text
  .error {
    color: red;
  }
}

//Applying styling to all the flexItems in the website
.flexItems {
  width: 100%;
  height: 100%;
  flex: 1 1;
}

//Setting up the basic flex settings for a body
.flexSetup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  //Applied to classes that need column direction, rather than default row
  &.column {
    flex-direction: column;
  }

  //Additional classes for where the gap amount may be different than the default setup

  &.noGap {
    gap: 0px;
  }

  &.smallGap {
    gap: 10px;
  }

  &.doubleGap {
    gap: 40px;
  }

  &.tripleGap {
    gap: 60px;
  }
}

//Setting up the basic page layout for the website
.PageBody {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $defaultBackground;

  &.dark {
    background-color: $darkBackground;
  }

  &.light {
    background-color: $lightBackground;
    color: black;
  }

  .mainBody {
    flex: 5 5;
    margin-left: 20%;
    margin-bottom: 10%;
  }

  .main {
    flex: 5 5;
  }
}

//Making a background to go behind forms
//Within this is all the styling that is needed for the login and signup form
.formBackground {
  width: 60%;
  background-color: $defaultAlt;
  border-radius: 10%;
  margin-bottom: 20px;
}

//Code used to FADE OUT unwanted elements on a page
.hideGoals {
  /* Hiding all elements that I have chosen to hide, so it focuses on the form*/
  .hideElement {
    opacity: 0.1;
  }
}

//Code to make the Header and Sidebar of the page
/* Styling the header of pages on the website*/
.header {
  height: 60px;
  background-color: black;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;

  .light {
    color: white;
  }

  p {
    margin-top: 5px;
    font-size: $subheadingText;
    cursor: pointer;
  }

  img {
    height: 50px;
  }
}

/* Styling for the sidebar of the website */
.sideBar {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100vh;

  .sideBarHeader {
    height: 60px;
    display: flex;
    justify-content: right;
    align-items: center;
    background-color: black;
    height: 100%;
  }

  .sideBarContent {
    background-color: $lightAlt;
    flex: 8 8;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;

    .sideBarElement {
      font-size: $smallText;
      transition: background-color 1s;
      cursor: pointer;

      &:hover {
        background-color: $defaultBackground;
      }
    }
  }
}

//Styling some elements of the SignIn / SignUp page
.transfer{
  font-size: $buttonText;
  margin-bottom: 5px;
  font-weight: 500;

  &.error{
    color: red;
  }
}

//Code to style the Goals Page and components on the website
.Goals {
  margin-top: 40px;
  margin-bottom: 20px;

  .GoalsHeader {
    font-size: $headingText;

    .titleArea {
      span:hover {
        text-decoration: underline;
      }
    }

    .buttonArea {
      gap: 10%;
      margin-top: 10px;
    }

    .goalOptionsArea {
      margin-top: 10px;

      .optionsLevel {
        display: flex;
        justify-content: center;
        align-items: space-evenly;
        gap: 20px;

        .option {
          font-size: $smallText;
        }
      }
    }
  }

  .IndividualGoals {
    //Styling the goal components on the page
    .homePageGoal {
      width: 60vw;
      margin-top: 20px;
      border-radius: 25px;

      &.default {
        background-color: $defaultAlt;
        color: black;
        border: 2px solid black;
      }

      &.light {
        background-color: $lightAlt;
        color: white;
        border: 2px solid black;
      }

      &.dark {
        background-color: $darkAlt;
        color: black;
        border: 2px solid $darkAlt;
      }

      .hpgHeader {
        flex: 2 2;
        border-bottom: 1px solid black;
        font-size: $subheadingText;
        span {
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        p {
          font-size: $smallText;
        }

        .hpgHeaderLine {

          &.progress {
            width: 60%;
          }
        }

        .completedBanner {
          background-color: black;
          color: white;

          p {
            font-size: $subheadingText;
          }
        }
      }

      .hpgMain {
        font-size: $smallText;
        flex: 1 1;
        min-height: 20px;

        p {
          margin-top: 5px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .hpgFooter {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 1 1;

        .hpgFooterEmpty {
          flex: 9 9;
        }

        .hpgFooterContent {
          height: 20px;
          border: 1px solid black;
          background-color: orange;
          cursor: pointer;
          border-radius: 10px;
        }
      }

      .options {
        margin: 5px;
      }
    }
  }
}

//Code to style the Timetable page, and components, on the website
.timetable {
  .timetableHeader {
    p {
      font-size: $headingText;
      padding: 10px;
    }
  }

  .error{
    font-size: $smallText;
  }

  input{
    &.light{
      color: white;
    }
  }

  .TimeTableDisplay {
    margin-top: 10px;
    margin-bottom: 40px;

    .TTDHeader {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 80%;
      border: 4px solid black;
      background-color: $defaultAlt;

      img {
        flex: 1 1;
        width: 50px;
        height: 50px;
        cursor: pointer;

        &:hover {
          border: 2px solid white;
        }
      }

      p {
        flex: 10;
        font-size: $subheadingText;
        color: black;
      }
    }

    .TTDMain {
      .timetableDay {
        .dayHeader {
          font-size: $subheadingText;
          border: 4px solid black;
          text-align: center;
          width: 80%;
          background-color: $darkBackground;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .dayEvents {
          width: 80%;

          .eventLine {
            border-right: 4px solid black;
            border-left: 4px solid black;
            background-color: $lightAlt;

            .event {
              height: 100%;
              background-color: $lightBackground;

              &.complete {
                background-color: gold;
              }

              p {
                color: black;
                font-size: $smallText;

                //Trying to help differentiate between eventName and eventDetails
                &.eventName {
                  font-weight: 700;
                }

                &.eventDetails {
                  font-style: italic;
                }
              }

              &.left {
                border-bottom: 4px solid black;
                border-right: 4px solid black;
              }

              &.middle {
                border-bottom: 4px solid black;
                border-right: 4px solid black;
              }

              &.right {
                border-bottom: 4px solid black;
              }

              &.blank {
                background-color: transparent;
              }

              .eventFooter {
                margin-top: 5px;

                .blank {
                  flex: 4 4;
                }

                .button {
                  height: 20px;
                  background-color: orange;
                  border-radius: 10px;

                  &:hover {
                    border: 2px solid white;
                  }
                }

                .completed {
                  font-weight: 700;
                  font-style: italic;
                }
              }
            }

            .noEvents {
              width: 100%;
              padding: 20px;
              font-size: $subheadingText;
              border: 4px solid black;
            }

            .option {
              &:hover {
                background-color: $darkAlt;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

//Code for all completed elements on the website
#completed {
  background-color: gold;
  color: black;
}

/* Styling for the progress bar of the page */
.container {
  width: 20%;
  background-color: #ddd;
  border: 1px solid black;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.progress-bar {
  width: 20%;
  background-color: #4caf50;
  text-align: center;
  line-height: 26px;
  font-size: $smallText;
  color: white;
  padding: 0px;
}

//Styling the MakeA... forms
#MakeForm {
  padding: 10px;
  margin-top: 10px;
  margin-left: 20vw;
  margin-bottom: 20px;
  opacity: 1;
  width: 60vw;
  border-radius: 20px;
  background-color: $defaultAlt;
  color: black;

  &.accounts {
    margin-left: 0px;
  }

  &.light {
    color: white;
    background-color: $lightAlt;
  }

  .formHeader {
    flex: 1 1;
    margin-bottom: 5px;

    .headerTitle {
      flex: 8 8;
      font-size: $headingText;
    }
  }

  .formContent {
    flex: 6 6;

    form {
      margin-left: 5%;
    }

    .formLine {
      text-align: left;

      span {
        font-size: $subheadingText;
      }

      button {
        margin-top: 5px;
      }

      .lineInput {
        margin: 5px;

        input {
          width: 80%;
        }

        &.light {
          input {
            color: white;
            &::placeholder {
              color: $lightBackground;
            }
          }
          option {
            color: black;
          }
        }
      }
    }

    .indent {
      margin-left: 30px;
    }

    .buttonLine {
      flex: 1 1;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: space-evenly;
      margin-bottom: 10px;

      .exitImg {
        height: 60px;
        width: 60px;
      }
    }

    .skillsOutput {
      font-size: $smallText;

      .skillLine {
        margin-bottom: 5px;
      }

      button {
        width: 28px;
        height: 28px;
        padding: 10px;
        background-color: red;
        text-align: center;
        font-size: 18px;
      }
    }

    button {
      width: 100%;
    }

    img {
      &:hover {
        border: 2px solid white;
      }
    }

    select{
      &.light{
        border-bottom: 2px solid white;
        color: white;
      }
    }
  }
}

//Styling the goal page of the website
.Goal {
  flex-direction: row;

  //Implementing the code to hide elements
  .goalPage {
    margin-bottom: 40px;

    .completed{
      background-color: gold;
    }

    /* Styling the skills section of the page */
    .skillsOutput {
      span {
        margin-top: 10px;
        font-size: $headingText;
      }

      .skillInputLine {
        width: 60%;

        input{
          &.dark{
            color: white;
          }
        }

        button {
          margin-top: 15px;
          padding: 5px;
          width: 20%;
        }
      }

      .skillLine {
        margin: 10px;

        .individualSkill {
          p {
            font-size: $subheadingText;
          }

          button {
            width: 28px;
            height: 28px;
            padding: 10px;
            background-color: red;
            text-align: center;
            font-size: 18px;
          }
        }
      }
    }
  }

  .goalHeader {
    margin-top: 20px;
    margin-bottom: 20px;

    span {
      font-size: $headingText;
    }

    .goalHeaderLine {
      text-align: left;
      height: 30px;
    }

    p {
      font-size: $subheadingText;
    }

    .subgoalOf {
      color: rgb(251, 248, 103);
      font-style: italic;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .subgoalLine {
    text-align: left;
    width: 60%;
    height: 30px;
    margin-bottom: 10px;

    p {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .subgoals {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;

    p {
      font-size: $smallText;

      &.subgoalName {
        margin-left: 25vw;
      }
    }
  }

  //Styling the subheadings of the page
  .subheading {
    font-size: $subheadingText;
    margin-bottom: 20px;
  }

  .AddGoalButton {
    width: 60vw;
    margin-left: 20vw;
    margin-bottom: 20px;
  }

  .accounts {
    margin-top: 10px;
    margin-bottom: 30px;

    #MakeForm {
      p {
        font-size: $smallText;
      }

      .headerTitle {
        margin-top: 10px;
        font-size: $subheadingText;
      }

      .EntryDetails {
        text-align: left;
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        gap: 20px;

        textarea {
          min-width: 100%;
          max-width: 120%;
          min-height: 20vh;
          font-size: 20px;
          padding: 10px;
          margin-bottom: 20px;
          resize: none;
        }
      }
    }

    .accountsMain {
      .entry {
        border: 2px solid white;
        width: 80%;
        border-radius: 10px;
        background-color: $defaultAlt;

        &.light {
          background-color: $lightAlt;
        }

        .entryOf {
          color: rgb(224, 70, 70);
          margin: 5px;
        }
      }

      p {
        font-size: $smallText;
        text-align-last: left;
        margin-left: 20px;
        color: black;
        overflow-wrap: anywhere;

        &.light {
          color: white;
        }
      }

      .skillLine {
        margin: 10px;
        padding: 10px;

        .skill {
          color: rgb(34, 152, 34);

          &.light {
            color: yellow;
          }
        }
      }

      .entryDetails {
        padding: 10px;
        margin: 10px;
      }

      .title {
        font-size: $subheadingText;
      }

      .centered {
        text-align-last: center;
      }

      .entryFooter {
        .emptySpace {
          flex: 6 6;
        }

        .entryOptions {
          width: 100%;
          height: 100%;
          background-color: orange;
          padding: 16px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            border: 1px solid white;
          }
        }
      }
    }
  }
}

//Styling the MyAccount page of the website
.MyAccount {
  gap: 0px;

  //Styling for the main content of the account page
  .Account {
    width: 60%;

    //Styling the subheadings on the page
    .subheading {
      font-weight: 700;
      font-size: $headingText;
      margin: 20px;
    }

    //Styling the infoLines on the page
    .infoLine {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $subheadingText;

      .title {
        flex: 3 3;
        text-align-last: left;
        margin-left: 40px;
      }

      .info {
        flex: 2 2;
        font-weight: 500;

        &.imgLocation {
          #displayImg {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  //Styling the popup windows on the site
  .changeInfoPopup {
    background-color: $defaultAlt;
    color: black;
    border-radius: 50px;

    .popupHeader {
      font-size: $headingText;
      padding: 10px;
    }

    .popupMain {
      .popupLine {
        font-size: $smallText;

        //Styling the file input element
        .file {
          cursor: pointer;
          font-weight: 500;
          padding: 5px;

          //Creating a border to signify they can select it
          &:hover {
            border: 2px solid white;
          }

          img {
            height: 50px;
            width: 50px;
          }
        }
      }
    }

    .popupFooter {
      margin: 10px;
    }
  }
}

.settingsComp {
  p {
    font-size: $subheadingText;
    padding: 10px;
  }

  .subheading {
    font-size: $headingText;
  }
}

//Styling the verificationFailure section of the website
.verificationFailure{
  overflow-y: hidden;
  p{
    font-size: $subheadingText;

    &.lighter{
      font-weight: 500;
    }
  }
}
